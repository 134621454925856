<template>
    <section class="cont companies">
       <!-- 面包屑 -->
       <el-row class="crumbs-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
             <el-breadcrumb-item>系统管理</el-breadcrumb-item>
             <el-breadcrumb-item>租户/商户管理</el-breadcrumb-item>
          </el-breadcrumb>
       </el-row>
       <el-row class="content-box">
          <!-- 查询框 -->
          <el-row class="search-box">
             <el-row class="search-row">
                <el-row class="search-item">
                   <label>单位名称：</label>
                   <el-input class="width-220" v-model="param.companyNameCode" :placeholder="$t('msg.company_name_credit_code')"  clearable></el-input>
                </el-row>
                <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
                <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
             </el-row>
          </el-row>
          <!-- 主体内容 -->
          <el-row class="table-box">
             <!-- 表格按钮 -->
             <div class="table-head-btn">
               <el-row></el-row>
                <el-row class="right-btn">
                   <el-button type="primary"  plain><span @click="handleJump('/hotel_manage', '4', '1-0')" v-text="$t('msg.hotel_manage')">酒店管理</span></el-button>
                   <el-button class="bg-gradient" type="primary"  @click="handleAddEdit('add')"><span v-text="$t('msg.new_company')">新增单位</span></el-button>
                </el-row>
             </div>
             <!-- 表格 -->
             <el-table
                   ref="singleTable"
                   v-loading="loading"
                   :data="tableData"
                   highlight-current-row
                   :stripe="true"
                   border fit
                   lazy
                   :load="getStore"
                   row-key="id"
                   @row-click="(row) => handleCurrentChange(row)"
                   :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column prop="number" :label="$t('msg.logo')" width="100">
                   <template slot-scope="scope">
                      <img v-if="scope.row.companyLogo" style="width: 35px;height: 35px; border-radius: 50%" :src="scope.row.companyLogo" alt="">
                      <img v-else style="width: 35px;height: 35px;border-radius: 50%" src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" alt="">
                   </template>
                </el-table-column>
                <el-table-column prop="companyName" :label="$t('msg.company_name')" width="200"></el-table-column>
                <el-table-column prop="companyCode" :label="$t('msg.company_code')" width="170"></el-table-column>
                <el-table-column :label="$t('msg.company_nature')" width="100">
                   <template slot-scope="scope">
                      <p>{{ scope.row.companyNature | filterNature(that) }}</p>
                   </template>
                </el-table-column>
                <el-table-column :label="$t('msg.industry')" width="160">
                   <template slot-scope="scope">
                      {{ scope.row.companyType | filterCompanyType(that) }}
                   </template>
                </el-table-column>
                <el-table-column prop="companyCode" :label="$t('msg.social_credit_code')" width="170"></el-table-column>
                <el-table-column prop="legalPerson" :label="$t('msg.legal_person')" width="90"></el-table-column>
                <el-table-column prop="mobile" :label="$t('msg.company_number')" width="120"></el-table-column>
                <el-table-column prop="email" :label="$t('msg.company_email')" min-width="200"></el-table-column>
                <el-table-column prop="createTime" :label="$t('msg.register_time')" width="180"></el-table-column>
                <el-table-column prop="info_time" :label="$t('msg.operating')" width="250" fixed="right" sortable>
                   <template slot-scope="scope">
                      <el-button type="text" icon="el-icon-edit" @click="handleAddEdit('edit',scope.row)" >
                         <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                      </el-button>
                      <el-button type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row.id, scope.row.tenantId)" >
                         <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                      </el-button>
                      <el-button v-if="scope.row.bizType === 'SHOPPING'" type="text" icon="el-icon-goods" @click="handleSettleIn(scope.row.id)" >
                         <el-link type="primary">入驻酒店</el-link>
                      </el-button>
                      <el-button v-if="scope.row.bizType === 'SHOPPING'" type="text" icon="el-icon-setting" @click="handleConfig(scope.row.id)" >
                         <el-link type="primary">商家配置</el-link>
                      </el-button>
                   </template>
                </el-table-column>
             </el-table>
             <!-- 分页 -->
             <pagination :total="total" :page-size="param.limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
          </el-row>
          <!--入驻酒店-->
          <el-form label-width="105px">
             <el-dialog title="入驻酒店" :visible.sync="isShow" width="800px">
                <el-row>
                   <el-col class="table-box" :span="11">
                      <div class="top-text"><span>未选酒店</span></div>
                      <el-table
                            :data="notHotelData"
                            border
                            height="252"
                            @selection-change="handleSelectionChange"
                            :stripe="true">
                         <el-table-column type="selection"></el-table-column>
                         <el-table-column prop="hotelName" label="酒店名称"></el-table-column>
                      </el-table>
                   </el-col>
                   <el-col class="table-box-arrow" :span="2">
                      <p @click="saveSettleIn"><i class="el-icon-d-arrow-right"></i></p>
                   </el-col>
                   <el-col class="table-box" :span="11">
                      <div class="top-text"><span>已选酒店</span></div>
                      <el-table
                            :data="inHotelData"
                            border
                            height="252"
                            :stripe="true"
                            >
                         <el-table-column prop="hotelName" label="酒店名称"></el-table-column>
                         <el-table-column :label="$t('msg.remove')" fixed="right">
                            <template slot-scope="scope">
                               <el-button @click="handleRemove(scope.row.id)" type="text" style="color: #F56C6C;" icon="el-icon-delete" >
                                  <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                               </el-button>
                            </template>
                         </el-table-column>
                      </el-table>
                   </el-col>
                </el-row>
             </el-dialog>
          </el-form>
          <!-- 商家配置 -->
          <el-form label-width="105px">
             <el-dialog title="商家配置" :visible.sync="isShowCfg" width="510px">
                <div class="execute">
                   <div class="execute-room">
                      <div class="top-text"><span>分类列表</span></div>
                      <el-tree
                            ref="tree"
                            :data="goodsData"
                            show-checkbox
                            node-key="id"
                            :default-expanded-keys="checkedArr"
                            :default-checked-keys="checkedArr"
                            :props="defaultProps">
                      </el-tree>
                   </div>
                </div>
                <span slot="footer" class="dialog-footer">
                  <el-button  type="primary" @click="getCheckedNodes" v-text="$t('msg.save')">保存</el-button>
                </span>
             </el-dialog>
          </el-form>
       </el-row>
    </section>
</template>
<script>
import { urlObj } from '@/api/interface'
import { system } from '@/api/interface/system'
import { getDict, CustomArray } from '@/common/js/common'
import { mapState } from 'vuex'
export default {
   name: 'companies',
   data(){
      return{
         that: this,
         tableData: [],        // 公司列表
         hotelIdArr: [],       // 未入驻酒店ID
         notHotelData: [],     // 酒店列表
         inHotelData: [],      // 入驻酒店列表
         goodsData: [],        // 商品分类列表
         maps: new Map(),      // 表格数子节点
         loading: true,        // 开启加载中效果
         total: 0,             // 表格数据总数
         tenantId: '',         // 所属租户的商户ID
         stateType: 'TENANT',  // 添加/编辑用保存状态
         companyName: '',      // 租户名称/传递到酒店页面
         isShow: false,        // 是否显示入驻弹窗
         isShowCfg: false,     // 是否显示配置弹窗
         companyId: '',        // 当前公司id
         defaultProps: {
            children: 'children',
            label: 'name'
         },
         checkedArr: [],        // 菜单列表默认选中的id数组
         param: {
            page: 1,
            limit: 0,
            companyNameCode: '',
         },
         type: 'TENANT',
         del_success: "删除成功！",
         confirm: '确定',
         cancel: '取消',
         confirm_remove: '确定移除？',
         prompt: '提示！',
         select_success: '请先选中租户！',
         settle_in_success: '入驻成功！',
         remove_success: '移除成功！',
         save_success: '保存配置成功！'
      }
   },
   beforeRouteEnter(to, from, next){
      if (from.name === 'companiesAddEdit'){
         next(vm => {
            vm.getCompanies()
         })
         return
      }
      next()
   },
   beforeRouteLeave(to, from, next){
      if (to.name === 'companiesAddEdit'){
         this.$store.dispatch('setCachePage', from.name)
         next()
         return
      }
      this.$store.dispatch('setCachePage', 'fakePage')
      next()
   },
   computed: {
      ...mapState(['dictData'])
   },
   mounted() {
      getDict(['company-type', 'company-nature'])
      this.param.limit = sessionStorage.getItem('pageSize') * 1
      this.getCompanies()
   },
   inject: ['reload'],
   methods: {
      // 获取公司列表
      getCompanies(){
         let param = { ...this.param }
         const { accountType } = JSON.parse(sessionStorage.getItem('userInfo'))
         if (accountType === 'PLATFORM' ||
            accountType === 'TENANT') param.type = 'TENANT'
         if (accountType === 'STORE') param.type = 'STORE'
         const url = system.companiesPaging
         this.$axios.post(url, param).then(res => {
            if (res.searchCount) {
               this.total = res.total
               this.loading = false
               let tableData = res.records
               tableData.forEach(item => item.companyLogo && (item.companyLogo = window.getResourcesHost() + item.companyLogo))
               let customArray = new CustomArray(tableData)
               this.tableData = customArray.changeKey('hasChildren', 'leaf')
            }
         })
      },
      // 点击下拉获取商户列表
      getStore(tree, treeNode, resolve){
         this.maps.set(tree.id, { tree, treeNode, resolve })
         const param = { tenantId: tree.id }
         const url = system.companies
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success) {
               res.records.forEach(item => item.companyLogo && (item.companyLogo = window.getResourcesHost() + item.companyLogo))
               resolve(res.records)
            }
         })
      },
      // 重新获取当前子节点数据
      refreshLoadTree(parentId) {
         // 根据父级id取出对应子节点数据
         const { tree, treeNode, resolve } = this.maps.get(parentId)
         this.$set(this.$refs.singleTable.store.states.lazyTreeNodeMap, parentId, [])
         tree && this.getStore(tree, treeNode, resolve)
      },
      // 搜索用户
      handleQuery(bool){
         if (bool) return this.getCompanies()
         this.param.companyNameCode = ''
         this.getCompanies()
      },
      // 改变每页数
      pageChange(num){
         this.param.limit = num
         this.getCompanies()
      },
      // 改变当前页
      handlePaging (num) {
         this.param.page = num
         this.getCompanies()
      },
      // 点击当前行选中
      handleCurrentChange: (() => {
         let id
         return function (row) {
            if (row.id !== id){
               this.companyName = row.companyName
               this.stateType = 'STORE'
               if (row.type === 'TENANT'){
                  this.tenantId = row.id
               }else if (row.type === 'STORE' || row.type === 'BUSINESS'){
                  this.tenantId = row.tenantId
               }
               id = row.id
            }else {
               // 取消选中 重置属性值
               id = ''
               this.companyName = ''
               this.stateType = 'TENANT'
               this.tenantId = ''
               this.$refs.singleTable.setCurrentRow();
            }
         }}
      )(),
      // 添加/编辑租户/商户
      handleAddEdit(act, row){
         let type = this.stateType
         if (act === 'edit') {
            sessionStorage.setItem('companyInfo', JSON.stringify(row))
            type = row.type
         }
         this.$router.push({path: '/companies_add_edit',query: {act, type, "tenantId": this.tenantId}})
      },
      // 删除租户/商户
      handleDel(id, tenantId){
         this.$confirm(this.confirm_remove, this.prompt, {
            confirmButtonText: this.confirm,
            cancelButtonText: this.cancel,
            type: 'warning'
         }).then(() => {
            let ids = []; ids.unshift(id)
            let param = { ids: ids.toString() }
            let url = system.delCompany;
            this.$axios.post(url, param).then(() => {
               this.$message({ showClose: true, message: this.del_success, type: 'success' })
               tenantId ? this.refreshLoadTree(tenantId) : this.getCompanies()
            })
         })
      },
      // 跳转到酒店列表页
      handleJump(path, menuIndex, sideIndex){
         if (!this.tenantId) return this.$message({ showClose: true, message: this.select_success, type: 'warning'})
         sessionStorage.setItem('menuIndex', menuIndex)
         sessionStorage.setItem('sideIndex', sideIndex)
         this.$router.push({path: path,query: {'storeId': this.tenantId, 'companyName': this.companyName}})
         this.reload()
      },
      // 入驻酒店
      handleSettleIn(id){
         this.isShow = true
         this.companyId = id
         this.getSettledHotel()
      },
      // 入驻酒店 获取已入驻酒店列表
      getSettledHotel(){
         const url = system.getBindingHotel
         const param = { companyId: this.companyId }
         this.$axios.post(url, param).then(res => {
            if (res.success) this.inHotelData = res.records; this.getNotSettledHotels()
         })
      },
      // 入驻酒店 获取未入驻酒店列表
      getNotSettledHotels(){
         const url = urlObj.getHotelList
         this.$axios.post(url, {}, 'json').then(res => {
            if (res.success){
               let hotelData = res.records
               for (let i = hotelData.length - 1; i >= 0; i --){
                  for (let j = 0; j <= this.inHotelData.length - 1; j ++){
                     if (hotelData[i].id === this.inHotelData[j].id) { hotelData.splice(i, 1); break; }
                  }
               }
               this.notHotelData = hotelData
            }
         })
      },
      // 入驻酒店 监视未入驻列表的选中变化
      handleSelectionChange(val){
         this.hotelIdArr = val.map(item => item.id)
      },
      // 入驻酒店 保存入驻
      saveSettleIn(){
         const url = system.bindingHotel
         const param = { companyId: this.companyId, hotelIdArr: this.hotelIdArr }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success){
               this.$message({ showClose: true, message: this.settle_in_success, type: 'success' })
               this.getSettledHotel()
            }
         })
      },
      // 入驻酒店 移除入驻
      handleRemove(hotelId){
         const url = system.removeHotel
         const param = { companyId: this.companyId, hotelId }
         this.$axios.post(url, param).then(res => {
            if (res.success){
               this.$message({ showClose: true, message: this.remove_success, type: 'success' })
               this.getSettledHotel()
            }
         })
      },
      // 配置服务
      handleConfig(id){
         this.isShowCfg = true
         this.companyId = id
         this.checkedArr = []
         this.getCategory()
         this.getCheckedCategory()
      },
      // 配置服务 获取商品分类
      getCategory(){
         const url = urlObj.getGoodsCategory
         const param = { isDelete: 'NO' }
         this.$axios.post(url, param, 'json').then(res => {
            if (res.success){
               let goodsData = res.children
               goodsData.forEach(item => item.disabled = true)
               this.goodsData = goodsData
            }
         })
      },
      // 配置服务 获取已选中商品分类
      getCheckedCategory(){
         const url = urlObj.getSecondCategory + `/${this.companyId}`
         this.$axios.get(url, {}).then(res => {
            if (res.success){
               let categoryData = res.records
               let checkedArr = []
               categoryData.forEach(item => checkedArr.push(item.categoryId))
               this.checkedArr = checkedArr
            }
         })
      },
      // 配置服务 保存配置
      getCheckedNodes(){
         // 获取当前选中的元素id数组
         let checkedKeys = this.$refs.tree.getCheckedNodes()
         // 过滤出子节点元素
         checkedKeys = checkedKeys.filter(item => item.level === 2)
         let categoryIdArr = []
         checkedKeys.forEach(item => categoryIdArr.push(item.id))
         let param = { companyId: this.companyId, categoryIdArr }
         const url = system.saveBusinessCfg
         this.$axios.post(url, param, 'json').then(res => {
            res.success && this.$message({ showClose: true, message: this.save_success, type: 'success' })
         })
      }
   },
   filters: {
      filterNature(val, that){
         if (val && that.dictData['company-nature']) return that.dictData['company-nature'][val] || val
      },
      filterCompanyType(val, that) {
         if (val && that.dictData['company-type']) return that.dictData['company-type'][val] || val
      }
   }
}
</script>

<style lang="scss" scoped>
   .top-text{
      font-size: 14px;
      padding-left: 20px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #ebeef5;
   }
   .table-box-arrow{
      text-align: center;
   }
   .el-icon-d-arrow-right{
      margin-top: 200%;
      font-size: 1.8rem;
      cursor: pointer;
   }
</style>
